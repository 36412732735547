// Color
$color-blue-800: #3A7BD5;
$color-blue-600: #00D2FF;
$color-blue-50: #EDF8FE;

$color-grey-800: #707070;
$color-grey-600: #adb1b5;
$color-grey-50: #F3F3F3;

$input-bg: #f4f4f4;
$body-bg: #fafafa;
$font-family-base: 'CircularStd-Book';