@include fontFace('CircularStd-Book','../fonts/CircularStd-Book');

body {
    @include font-smoothing('antialiased');
    color: $color-grey-800;
    font-size: 14px;
    scroll-behavior: smooth;
}

html {
    overflow: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none; 
}

::-webkit-scrollbar {
    width: 0px;  
    background: transparent; 
}

.slick-slide {
    outline: none
}