@media (max-width: 575.98px) {
    .slick-list{
        padding:0 20% 0 5px;
    }

    .card-scroll {
        padding-left: 0;
        padding-right: 0;
    }

    .section-functional {
      .col-spacing {
        margin: -20px;

        @include e('item') {
          padding: 20px;
        }
      }
    }

    .section-sample {
      @include e('content'){
        margin-top: 2rem;
        flex-wrap: nowrap;
        overflow-x: auto;
      }

      @include e('scroll'){
        flex-wrap: nowrap;
        overflow-x: visible;
      }
    }
}

@media (max-width: 767px) {
  .mobile-menu {
    background: linear-gradient(244deg,#3a7bd5,#00d2ff);
    padding: 1rem;
    width: 55%;
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    z-index: 100;

    &.open {
      transform: translateX(0);
    }

    .navbar-nav {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      .nav-link {
        font-size: 18px;
        color: #ffffff;
      }
    }
  }  

  .menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0,0,0,0.5);
    display: none;
    z-index: 99;

    &.open {
      display: block;
    }
  }

}

@media (min-width: 768px) {
  body {
    @include font-smoothing('antialiased');
    color: $color-grey-800;
    font-size: 16px;
  }

  .navbar-homepage {
    background: transparent;
    .navbar-brand {
      @include e('img') {
        width: 180px;
        max-width: 100%;
        filter: none;
      }
    }
  }

  .section-masthead {
    background-color: #ffffff;
    background-image: url('../img/cover-masthead.png');
    background-size: cover;
    background-position: center;
    height: 100vh;

    @include e('text') {
      text-align: right;
      color: #ffffff;
      padding-top: 8rem;

      .btn {
        margin-top: 2rem;
      }
    }
  }

  .section {
    @include e('title') {
      margin-bottom: 2rem;
      padding: 0;
    }

    @include e('btn') {
      padding: 0;
      margin-top: 3rem;
    }
  }

  .section-product {
    padding: 4rem 0;
    background-color: #ffffff;
  }

  .section-services {
    padding: 5rem 0;
    background-color: $color-blue-50;

    @include e('background') {
      padding: 3rem;
    }
  }

  .section-location {
    padding: 4rem 0;
    background: url('../img/cover-loc-left.png') left center no-repeat,
      url('../img/cover-loc-right.png') right center no-repeat,
      linear-gradient(244deg, #3a7bd5 0%, #00d2ff 100%);

    @include e('col') {
      padding: 4rem 0;
    }
  }

  .card-product {
    border: 1px solid $color-blue-50;
    background-color: #ffffff;
  }

  .section-about {
    padding: 3rem 0 0;
    @include e('card') {
      padding: 7rem 1rem;
    }
  }

  .footer {
    text-align: left;
  }

  .card-scroll {
    @include e('item') {
      padding: 15px;
    }
  }

  .section-masthead {
    @include e('text') {
      margin: 0;
      position: initial;
      top: initial;
      color: #ffffff;
      transform: initial;
      text-align: right;
    }
  }

  .section-team {
      padding: 4rem 0;
      background-color: #f7f7f7;
  }

  .section-featured {
      @include e('card') {
      border: 0;
      padding: 1.5rem 1rem 1.5rem 6rem;
      box-shadow: 1rem 1rem 5rem 1rem #f3f3f3;
    }
  }

  .section-functional {
    padding: 7rem 0;

    @include e('thumbnail') {
        width: 320px;
        height: 180px;
    }

    @include e('card'){
        height: 260px;
        width: 100%;
    }

    .row {
        &:nth-child(1) {
            padding-bottom: 3rem;

            .section-functional__wrapper {
                margin-right: -200px;
            }

            .section-functional__text {
              text-align: left;
            }
        }

        &:nth-child(2) {
            padding-top: 3rem;

            .section-functional__wrapper {
                margin-left: -200px;
            }

            .section-functional__text {
              text-align: right;
            }
        }
    }

    .col-spacing {
      margin: -25px;

      @include e('item') {
        padding: 25px;
      }
    }
  }

  .section-list {
    padding: 4rem 0;

    .vertical-column {
      padding-top: 4rem;
      
      @include e('item') {
        margin-bottom: 2rem;
      }
    }
  }

  .section-sample {
    padding: 4rem 0;

    @include e('content') {
      padding-top: 4rem;
    }
  }

  .section-featured {
    @include e('wrapper') {
        display: inline-block;
        z-index: 1;
        margin-right: -90px;
    }

    @include e('thumbnail') {
        width: 100px;
        height: 100px;
        background-color: #ffffff;
        border-radius: 50px;
        padding: 1rem;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.175);
        display: flex;
        justify-content: center;

        @include m('big') {
            width: 180px;
            height: 180px;
            border-radius: 90px;
        }
    }

    @include e('img') {
        width: 90px;
    }

    @include e('card') {
      border: 0;
      padding: 1rem 1rem 1rem 4rem;
      box-shadow: 1rem 1rem 5rem 1rem #f3f3f3;

      .card-body {
        padding: inherit;
      }
    }

  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {
  .section {
    @include e('title') {
      padding-top: 0;
      padding-bottom: 5rem;
      margin-bottom: 0;
    }
  }

  .section-services {
    padding-top: 6rem;
    padding-bottom: 6rem;

    @include e('background') {
      padding: 0 3rem 3rem;
    }

    .section__title {
        padding: 5rem 0;
    }
  }

  .section-location {
    padding: 6rem 0;

    @include e('col') {
      padding: 4rem 0;
    }
  }

  .section-about {
    padding-top: 6rem;

    @include e('card') {
      padding: 8rem 1rem;
    }
  }

  .section-team {
      padding: 6rem 0;
  }

  .section-product {
      padding: 6rem 0;
  }

  .card-scroll {
    @include e('item') {
      padding: 15px;
    }
  }

  .section-functional {
      padding: 10rem 0;
  }

  .section-list {
      padding: 6rem 0;
  }

  .section-sample {
      padding: 6rem 0;
  }
}
