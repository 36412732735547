@mixin fontFace($family,$src,$style: normal,$weight: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$src}.eot'); // IE9 compat
		src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
			url('#{$src}.woff') format('woff'), // standards
			url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
			url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

		font-style: $style;
		font-weight: $weight;
	}
}

@mixin e($element) {
    &__#{$element} {
        @content;
    }
}

@mixin m($modifier) {
    &--#{$modifier} {
        @content;
    }
}

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin box-shadows($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin rounded($radius: 0.25rem) {
  -webkit-border-radius: .25rem; 
  -moz-border-radius: .25rem;
  border-radius: .25rem;
}