.section-featured {

    @include e('wrapper') {
        display: inline-block;
        z-index: 1;
        margin-right: -50px;
    }

    @include e('thumbnail') {
        background-color: #ffffff;
        border-radius: 50px;
        padding: 1rem;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.175);
        display: flex;
        justify-content: center;

        @include m('big') {
            width: 100px;
            height: 100px;
            border-radius: 90px;
        }
    }

    @include e('img') {
        width: 50px;
    }

}

.section-functional {
    background-color: $color-blue-50;
    padding: 3rem 0;

    @include e('wrapper'){
        display: inline-block;
        z-index: 1;
    }

    @include e('thumbnail') {
        width: 320px;
        height: 180px;
        display: flex;
        justify-content: center;
    }

    @include e('img') {
        width: 100%;
        height: auto;
        border-radius: 1rem;
    }

    @include e('card'){
        height: 260px;
        width: 100%;
        border: none;
    }

    .row{
        &:nth-child(1) {
            .section-functional__wrapper {
                margin-right: -200px;
            }

            .section-functional__text {
                text-align: center;
            }

            .section-functional__card {
                background: linear-gradient(304deg, #3A7BD5 0%, #00D2FF 100%) 0% 0% no-repeat; 
            }
        }

        &:nth-child(2) {
            .section-functional__wrapper {
                margin-left: -200px;
            }

            .section-functional__text {
                text-align: center;
            }

            .section-functional__card {
                background: linear-gradient(125deg, #3A7BD5 0%, #00D2FF 100%) 0% 0% no-repeat;
            }
        }
    }

    .col-spacing {
        margin: -30px;
        @include e('item') {
            padding: 30px;
        }
    }
}

.section-list {
    background-color: #ffffff;
    padding: 3rem 0;
    
    @include e('card') {
        border: none;
        box-shadow: 0px 3px 24px rgba(243, 243, 243, 0.7);
    }

    .vertical-column {
        padding-top: 2rem;
        
        @include e('item') {
          margin-bottom: 2rem;
        }
    }    
}

.section-sample {
    background-color: #fafafa;
    padding: 3rem 0;
}